// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, SVG, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cLeUPYdDz"];

const serializationHash = "framer-8kzkn"

const variantClassNames = {cLeUPYdDz: "framer-v-14neckj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, dFlrgMQng: hover ?? props.dFlrgMQng, Iy12m6SA6: title ?? props.Iy12m6SA6 ?? "Integrations"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;hover?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Iy12m6SA6, dFlrgMQng, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cLeUPYdDz", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterzkp46l = activeVariantCallback(async (...args) => {
if (dFlrgMQng) {
const res = await dFlrgMQng(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8kzkn", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-14neckj", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"cLeUPYdDz"} onMouseEnter={onMouseEnterzkp46l} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Um9ib3RvIE1vbm8tNTAw", "--framer-font-family": "\"Roboto Mono\", monospace", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-line-height": "1.4em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-6014b03e-96b6-44fc-a8fe-1c6402d9a85e, rgb(180, 185, 177)))", "--framer-text-transform": "uppercase"}}>Integrations</motion.p></React.Fragment>} className={"framer-8r20mw"} fonts={["GF;Roboto Mono-500"]} layoutDependency={layoutDependency} layoutId={"V01bFppeH"} style={{"--extracted-r6o4lv": "var(--token-6014b03e-96b6-44fc-a8fe-1c6402d9a85e, rgb(180, 185, 177))", "--framer-paragraph-spacing": "0px"}} text={Iy12m6SA6} verticalAlignment={"top"} withExternalLayout/><SVG className={"framer-m5iv4r"} data-framer-name={"chevron_right"} layout={"position"} layoutDependency={layoutDependency} layoutId={"MYcJcBVv1"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 18 18\"><path d=\"M 0 9 L 4.5 4.5 L 0 0\" transform=\"translate(6.75 4.5) rotate(90 2.25 4.5)\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"var(--token-6014b03e-96b6-44fc-a8fe-1c6402d9a85e, rgb(181, 189, 197)) /* {&quot;name&quot;:&quot;Base / Light Blue [100]&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={3424596130} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8kzkn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8kzkn .framer-1sojbug { display: block; }", ".framer-8kzkn .framer-14neckj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 8px 8px 8px 8px; position: relative; width: min-content; }", ".framer-8kzkn .framer-8r20mw { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-8kzkn .framer-m5iv4r { flex: none; height: 18px; position: relative; width: 18px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8kzkn .framer-14neckj { gap: 0px; } .framer-8kzkn .framer-14neckj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-8kzkn .framer-14neckj > :first-child { margin-left: 0px; } .framer-8kzkn .framer-14neckj > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35.5
 * @framerIntrinsicWidth 145
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Iy12m6SA6":"title","dFlrgMQng":"hover"}
 * @framerImmutableVariables true
 */
const FramerKM5SkW_31: React.ComponentType<Props> = withCSS(Component, css, "framer-8kzkn") as typeof Component;
export default FramerKM5SkW_31;

FramerKM5SkW_31.displayName = "test";

FramerKM5SkW_31.defaultProps = {height: 35.5, width: 145};

addPropertyControls(FramerKM5SkW_31, {Iy12m6SA6: {defaultValue: "Integrations", displayTextArea: false, title: "Title", type: ControlType.String}, dFlrgMQng: {title: "Hover", type: ControlType.EventHandler}} as any)

addFonts(FramerKM5SkW_31, [{family: "Roboto Mono", style: "normal", url: "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7PqPRu-5Ip2sSQ.woff2", weight: "500"}])